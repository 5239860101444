






import { Vue, Component } from '$/lib/vueExt';

import type Step from './Step.vue';
import StepGroup from './StepGroup.vue';

@Component({
	provide() {
		return {
			// for some reason, the @Provide decorator doesn't work for this, so doing this the more traditional way
			stepsComponentInject : this,
		};
	},
})
export default class Steps extends Vue {

	steps: Step[]     = [];
	currentStep: Step = null;

	/**
	 * @returns the index of the current step in the steps array (also includes disabled steps)
	 */
	get currentStepIndex() {
		return this.steps.indexOf(this.currentStep);
	}

	/**
	 * @returns the array of all non-disabled steps
	 */
	get enabledSteps() {
		return this.steps.filter(step => !step.isDisabled);
	}

	/**
	 * @returns true if the first step is the current step
	 */
	get isFirstStep() {
		return _.first(this.enabledSteps) === this.currentStep;
	}

	/**
	 * @returns true if the last step is the current step
	 */
	get isLastStep() {
		return _.last(this.enabledSteps) === this.currentStep;
	}

	registerStep(step: Step) {
		if (!this.steps.includes(step)) {
			this.steps.push(step);

			// sort the steps by their order in the DOM
			const nodes = Array.from(this.$el.querySelectorAll(':not(.fl-steps) .fl-step'));
			this.steps.sort((step1, step2) => nodes.indexOf(step1.$el) - nodes.indexOf(step2.$el));
		}

		if (!this.currentStep) {
			this.activateStep(this.enabledSteps[0]);
		}
	}

	unregisterStep(step: Step) {
		this.steps = this.steps.filter(s => s !== step);
	}

	/**
	 * Activate a step by index or by the step component itself. Deactivate other steps
	 * The index can reference a disabled steps.
	 */
	 activateStep(stepOrGroupOrIndex: Step | StepGroup | number) {
		let stepToActivate: Step;

		if (typeof stepOrGroupOrIndex === 'number') {
			stepToActivate = this.steps[stepOrGroupOrIndex];
		}
		else if (stepOrGroupOrIndex instanceof StepGroup) {
			stepToActivate = this.steps.find(step => step.stepGroup === stepOrGroupOrIndex);
		}
		else {
			stepToActivate = stepOrGroupOrIndex;
		}

		if (!stepToActivate || stepToActivate.isDisabled || this.currentStep === stepToActivate) {
			// do nothing though perhaps this should throw an error
			return false;
		}

		const stepEvent = new Event('activateStep', { cancelable : true });
		this.$emit(stepEvent.type, stepEvent);

		if (stepEvent.defaultPrevented) {
			return false;	// event cancelled so bail
		}

		this.currentStep = stepToActivate;

		this.$emit('change', this.currentStep);
		return true;
	}

	async nextStep() {
		const currentIndex = Math.max(this.currentStepIndex, -1);
		let nextIndex      = currentIndex;
		let nextStep;

		// Sometimes the next step can get disabled during the onNext
		// If that happens, keep trying to move to the next step until we find one that isn't disabled or we run out of steps
		while (!nextStep || nextStep.isDisabled) {
			nextStep = this.steps.slice(++nextIndex).find(step => !step.isDisabled);

			if (!nextStep) {
				await this.done();
				return;
			}

			if (!(await this.currentStep.onNext(nextStep, this.currentStep))) {
				return; // SHOULDDO: Notify user of failure
			}
		}
		await nextStep.beforeActivated();
		this.activateStep(nextStep);
	}

	async previousStep() {
		const prevStep = _.findLast(this.steps.slice(0, Math.max(this.currentStepIndex, 0)), step => !step.isDisabled);

		if (!(await this.currentStep.onPrevious(prevStep, this.currentStep))) {
			return; // SHOULDDO: Notify user of failure
		}

		this.activateStep(prevStep);
	}

	async done() {
		if (await this.currentStep?.onNext(null, this.currentStep)) {
			this.$emit('finish');
		}
	}

}
