














































































import type { BModal }               from 'bootstrap-vue';
import { Vue, Component, Prop, Ref } from '$/lib/vueExt';
import ConfirmationModal             from '$/lib/widgets/ConfirmationModal.vue';

@Component({ model : { prop : 'file' } })
export default class FileViewer extends Vue {

	@Prop()
	readonly file: File;

	/**
	 * if true, adds approve/reject buttons to the modal and emits a review event
	 */
	@Prop({ default : false })
	readonly canReview: boolean;

	@Ref()
	readonly feedbackModal: ConfirmationModal;

	feedback = '';
	loading  = true;
	rotation = 0;

	get isImage() {
		return this.file ? this.file.mimeType.match('image/*') : false;
	}

	get style() {
		return `transform : rotate(${this.rotation * 90}deg)`;
	}

	async reviewFile(wasApproved: boolean) {
		let feedbackModalResult = false;

		if (!wasApproved) {
			feedbackModalResult = await this.feedbackModal.showConfirm();
			if (!feedbackModalResult) {
				return;
			}
		}
		this.$emit('review', wasApproved, feedbackModalResult ? this.feedback : null);
		(this.$refs['file-viewer'] as BModal).hide();
		this.feedback = '';
	}

	rotateClockwise() {
		this.rotation++;
	}

	rotateCounterClockwise() {
		this.rotation--;
	}

	onHidden() {
		this.rotation = 0;
		this.loading  = true;
		this.$emit('update:file', null);
		this.$emit('hidden');
	}

}

export interface File {
	name: string;
	mimeType: string;
	contentURL: string;
}
