/**
 * All possible file status states
 */
export enum FileStatus {
	Uploading = 'uploading',
	Saved     = 'saved',
	Error     = 'error',
	Cancelled = 'cancelled',
}

/**
 * All possible types of documents that users can upload
 */
export enum DocumentType {
	BuildingOwnership            = 'buildingOwnership', // role verification, also attaches to building and needed for reporting for small landlords
	UserPhotoID                  = 'userPhotoID', // for user verification
	PMBusinessCard               = 'pmBusinessCard', // role verification
	PMManagementAgreement        = 'pmManagementAgreement', // role verification
	OrgCompanyProof              = 'orgCompanyProof', // role verification
	LeaseAgreement               = 'leaseAgreement', // needed for reporting for small landlords, also for collections
	CollectionsRentalLedger      = 'collectionsRentalLedger', // needed for collections
	CollectionsRentalApplication = 'collectionsRentalApplication', // needed for collections
	CollectionsOrderJudgement    = 'collectionsOrderJudgement', // needed for collections
	CollectionsTenantID          = 'collectionsTenantID', // needed for collections
	Unknown                      = 'unknown' // some existing files have weird names and can't be categorized properly. SHOULDDO: fix these
}

/**
 * An array of all the document types used for role verification
 */
export const RoleVerificationDocumentTypes = [
	DocumentType.BuildingOwnership,
	DocumentType.PMBusinessCard,
	DocumentType.PMManagementAgreement,
	DocumentType.OrgCompanyProof,
];

/**
 * An array of all the blacklisted file extensions (without a preceding .)
 */
export const ExtensionBlacklist = [ 'exe', 'com', 'bat', 'sh', 'dmg', 'dll', 'jar' ];

/**
 * An array of all the blacklisted MIME types
 */
export const MimeTypeBlacklist = [
	'application/exe',
	'application/x-msdownload',
	'application/x-exe',
	'application/dos-exe',
	'application/x-winexe',
	'application/msdos-windows',
	'application/x-msdos-program',
	'application/vnd.microsoft.portable-executable',
	'application/java-archive',
	'application/x-apple-diskimage',
	'vms/exe',
	'application/x-sh',
];

export type FileUploadProgress = (uploadProgressPercentage: number) => void
