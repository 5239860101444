import { Field, JSONable } from '$/entities/lib/JSONable';
import type { EntityID }   from '$/entities/BaseEntity';
import type { File }       from '$/entities/File';

export enum VerificationType {
	Upload  = 'upload',			// used for User and Role Verification
	Kount   = 'kount',			// used only for User Verification
	Persona = 'persona',		// used only for User Verification
}

export enum VerificationStatus {
	Started   = 'started',
	Submitted = 'submitted',
	Approved  = 'approved',
	Rejected  = 'rejected',
}

export class VerificationResult extends JSONable {

	constructor(type: VerificationType, status: VerificationStatus, score?: number, reasons?: string[], additionalData?: any, invalid?: boolean, date?: Date) {
		super();
		this.date           = date ?? new Date();
		this.type           = type;
		this.status         = status;
		this.score          = score;
		this.reasons        = reasons;
		this.additionalData = additionalData;
		this.invalid        = invalid;
	}

	@Field()
	type: VerificationType;

	@Field()
	date: Date;

	@Field()
	status: VerificationStatus;

	@Field()
	score?: number;

	@Field()
	reasons?: string[];

	@Field()
	invalid?: boolean;

	@Field()
	additionalData?: FileResults | PersonaData | any;

	getFiles(): FileResult[] {
		return this.type === VerificationType.Upload ? (this.additionalData as FileResults)?.files : [];
	}

	getFileResultById(fileId: string): FileResult {
		if (this.type === VerificationType.Upload) {
			return (this.additionalData as FileResults).files.find(file => file.fileId === fileId);
		}
	}

	getInquiryId(): string {
		return this.type === VerificationType.Persona ? (this.additionalData as PersonaData).inquiryId : '';
	}

	static createUploadResult(
		verificationStatus: VerificationStatus,
		files: PossibleArray<{ file: File; rejectionReason?: string }>,
		{ invalid = false, date = new Date() }: CreateUploadResultOptions = {}
	): VerificationResult {
		const verificationResult          = new VerificationResult(VerificationType.Upload, verificationStatus);
		verificationResult.invalid        = invalid;
		verificationResult.date           = date;
		verificationResult.additionalData = {
			files : _.castArray(files).map(file => ({
				fileId : file.file.id,
				status : file.rejectionReason ? VerificationStatus.Rejected : VerificationStatus.Submitted,
				reason : file.rejectionReason,
			})),
		} as FileResults;
		return verificationResult;
	}

}

interface CreateUploadResultOptions {
	reasons?: PossibleArray<string>;
	invalid?: boolean;
	date?: Date;
}

export interface FileResults {
	files: FileResult[];
}

export interface FileResult {
	fileId: string;
	status: VerificationStatus;
	reason?: string;
	lastUpdatedBy?: EntityID;
}

export interface PersonaData {
	inquiryId: string;
}
