import { loadScript }       from '$/lib/utils';
import env, { Environment } from '$/lib/env';

import { Support }        from '$/entities/roles/Support';
import { Authentication } from '$/entities/Authentication';
import { User }           from '$/entities/User';
import { BaseRole }       from '$/entities/roles/BaseRole';

declare global {
	var clarity: any; 	// eslint-disable-line no-var
}

export async function loadClarity() {
	if (env.isEnvironmentNot(Environment.PROD) || BaseRole.current instanceof Support) {
		return;
	}

	// Setup Clarity
	window.clarity = function(...args) {
		window.clarity.q ||= [];
		window.clarity.q.push(args);
	};
	await loadScript('https://www.clarity.ms/tag/iirzhpw357');

	// Identify user
	const { userID } = await Authentication.getFreshSession();
	if (userID) {
		window.clarity('identify', userID);
		const role = await BaseRole.loadCurrent({ redirectToLogin : false });
		if (role) {
			setVariable('userName',  role.user.fullName);
			setVariable('roleType',  role.constructor.name);
			setVariable('roleEmail', role.user.email);
			setVariable('userEmail', User.current?.email);
		}
	}
}

function setVariable(key: string, value: string) {
	window.clarity('set', key, value);
}
