




































import { Vue, Component, Prop, Inject } from '$/lib/vueExt';
import Steps                            from './Steps.vue';
import type Step                        from './Step.vue';

@Component
export default class StepNavigation extends Vue {

	@Inject({ from : 'stepsComponentInject', default : undefined })
	readonly stepsComponentInject?: Steps;

	/**
	 * Use this prop to provide an explicit Steps component to use for navigation (rather than any injected Steps component).
	 */
	@Prop({ default : null })
	readonly stepsComponent?: Steps;

	@Prop({ default : false })
	readonly hideProgress: boolean;

	@Prop({ default : 'Next' })
	readonly nextLabel: string;

	@Prop({ default : 'Back' })
	readonly prevLabel: string;

	@Prop({ default : 'Done' })
	readonly finishLabel: string;

	/**
	 * If true, shows the Prev button even on the first step.
	 * This allows for going "back" past the first step, intended to allow for cancelling the steps flow.
	 */
	@Prop({ default : false })
	readonly allowPrevOnFirstStep: boolean;

	@Prop({ default : false })
	readonly block: boolean;

	/**
	 * @returns the parents Steps component, either from the prop or from the injected value.
	 */
	get steps(): Steps {
		return this.stepsComponent ?? this.stepsComponentInject;
	}

	get currentStep() {
		return this.steps?.currentStep;
	}

	get canPrev() {
		return this.steps?.isFirstStep && this.allowPrevOnFirstStep
			|| (!!this.steps?.enabledSteps.slice(0, this.steps?.currentStepIndex).length && !this.currentStep?.hidePrev);
	}

	get canNext() {
		if (!this.steps) {
			return false;
		}

		const currentIndex = this.steps.currentStepIndex;
		for (let i = currentIndex + 1; i < this.steps.steps.length; i++) {
			if (!this.steps.steps[i].isDisabled) {
				return true;
			}
		}
		return false;
	}

	get isNextVisible() {
		return !this.currentStep?.hideNext;
	}

	get isProgressVisible() {
		return !this.hideProgress && !this.currentStep?.noProgress;
	}

	get localNextLabel() {
		if (this.canNext) {
			return this.currentStep?.nextLabel || this.nextLabel;
		}

		return this.currentStep?.finishLabel || this.finishLabel;
	}

	get localPrevLabel() {
		return this.currentStep?.prevLabel || this.prevLabel;
	}

	get localButtonDivClass() {
		return (this.canPrev && this.isNextVisible) ? 'justify-content-between' : 'justify-content-end';
	}

	get stepDots() {
		const steps: Step[] = [];
		for (const step of this.steps.enabledSteps) {
			if (step.noProgress || (step.stepGroup && steps.some(resultStep => resultStep.stepGroup === step.stepGroup))) {
				continue;
			}
			steps.push(step);
		}
		return steps;
	}

	onPrev() {
		if (!this.steps?.currentStepIndex) {
			this.$emit('cancel');
		}
		return this.steps?.previousStep?.();
	}

	onNext(): Promise<void> {
		return this.canNext ? this.steps?.nextStep?.() : this.steps?.done?.();
	}

}
