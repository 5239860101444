export enum InvoiceStatus {
	Draft         = 'draft',
	Open          = 'open',
	Paid          = 'paid',
	Void          = 'void',
	Uncollectible = 'uncollectible',
}

export enum ChargeStatus {
	Succeeded = 'succeeded',
	Failed    = 'failed',
	Pending   = 'pending',
}

export enum InvoiceCollectionMethod {
	ChargeAutomatically = 'charge_automatically',
	SendInvoice         = 'send_invoice',
}
