import Axios            from 'axios';
import { ChargeStatus } from '$/lib/stripe';

import type { BaseImport, BaseImportRow } from '$/lib/import/BaseImport';
import type { Package }                   from '$/entities/Package';
import type { BaseRole }                  from '$/entities/roles/BaseRole';
import { Authentication }                 from '$/entities/Authentication';
import { Entity, EntityID, getEntityClass, SyncEntities } from '$/entities/BaseEntity';

import { Invoice, Organization as OrganizationCommon, PaymentMethod, SubscriptionRenewal } from '$/common/entities/Organization';
export *                                                                                   from '$/common/entities/Organization';

@Entity()
export class Organization extends OrganizationCommon {

	/**
	 * The amount of arrears the organization has in the org's original currency.
	 * Do not update this directly, instead use `this.getArrears()` which will update it by calling the server.
	 */
	arrearsOrig: number = 0;

	get isInArrears(): boolean {
		if (typeof Authentication.session?.isInArrearsOverride === 'boolean') {
			return Authentication.session.isInArrearsOverride;
		}
		return super.isInArrears;
	}

	/**
	 * If the organization has failed charges, this will contain the message from the last failed charge.
	 * Do not update this directly, instead use `this.getInvoices()` which will update it by calling the server.
	 */
	failedChargeMessage = '';

	/**
	 * If the client is authenticated, this returns the current user's Organization.
	 */
	@SyncEntities()
	static get current(): Organization {
		return (getEntityClass('BaseRole') as typeof BaseRole).current?.org;
	}

	static async loadCurrent(): Promise<Organization> {
		await (getEntityClass('BaseRole') as typeof BaseRole).loadCurrent();
		return this.current;
	}

	/**
	 * @returns the ID of the Organization for currently logged in user (if any)
	 */
	static get currentID(): EntityID {
		return (getEntityClass('BaseRole') as typeof BaseRole).current?.org.id;
	}

	async import<T extends BaseImportRow>(importer: BaseImport<T>, save = false) {
		return (await Axios.post(Organization.collectionUrl(`${this.id}/import/${importer.constructor.name}`), {
			// SHOULD DO: find a better way to omit cache, errors, warnings from each row before sending the payload
			rows : importer.rows.map(row => _.omit(row, [ 'errors', 'warnings', 'cache' ])),
			save,
		})).data;
	}

	async setPackage(pkg: Package, { promoCode = undefined } = {}) {
		await Axios.put(Organization.collectionUrl(`${this.id}/package`), { packageID : pkg.id, promoCode });
		await this.reload({ includeRelations : [ 'package' ] });
	}

	async getProratedPrice(pkg: Package, { promoCode = undefined } = {}): Promise<number> {
		return (await Axios.get(Organization.collectionUrl(`${this.id}/proratedPrice`), { params : { packageID : pkg.id, promoCode } })).data.price;
	}

	async setPaymentMethod(stripeToken: string) {
		await Axios.put(Organization.collectionUrl(`${this.id}/payment`), { stripeToken });
	}

	async getPaymentMethods(): Promise<(PaymentMethod)[]> {
		return (await Axios.get(Organization.collectionUrl(`${this.id}/payment`))).data;
	}

	async getInvoices(): Promise<Invoice[]> {
		const invoices: Invoice[] = (await Axios.get(Organization.collectionUrl(`${this.id}/invoices`))).data;
		this.failedChargeMessage  = invoices.find(invoice => invoice.charge?.status === ChargeStatus.Failed)?.charge?.failure_message;
		return invoices;
	}

	async getArrears(): Promise<number> {
		this.arrearsOrig = (await Axios.get(Organization.collectionUrl(`${this.id}/arrears`))).data;
		return this.arrearsOrig;
	}

	async getRenewal(): Promise<SubscriptionRenewal> {
		return (await Axios.get(Organization.collectionUrl(`${this.id}/renewal`))).data;
	}

	async setRenewal(renew: boolean) {
		await Axios.post(Organization.collectionUrl(`${this.id}/renewal`), { renew });
	}

	/**
	 * Invite someone to join a Landlord's organization
	 */
	async sendInvite(to: EmailAddress) {
		await Axios.post(Organization.collectionUrl(`${this.id}/invitation`), { email : to });
	}

	async getInvitations(): Promise<{ id: string; email: string; createdOn: Date; expiresOn: Date}[]> {
		return (await Axios.get(Organization.collectionUrl(`${this.id}/invitation`))).data;
	}

	async deleteInvitation(invitationID: EntityID) {
		await Axios.delete(Organization.collectionUrl(`${this.id}/invitation/${invitationID}`));
	}

	async payInvoices(invoiceIds: string[]) {
		return Axios.post(Organization.collectionUrl(`${this.id}/invoices/pay`), { invoiceIds });
	}

	async registerWithEquifax() {
		if (this.canRegisterWithEquifax) {
			await Axios.post(Organization.collectionUrl(`${this.id}/register-equifax`));
			await this.reload();
		}
	}

	async getKpi(kpiClassName: string, role?: BaseRole): Promise<any> {
		return (await Axios.get(
			Organization.collectionUrl(`${this.id}/kpi/${kpiClassName}`),
			{ params : _.compactObject({ roleId : role?.id }) }
		)).data;
	}

}
