








import { Vue, Component, Prop, Inject } from '$/lib/vueExt';

import type Steps     from './Steps.vue';
import type StepGroup from './StepGroup.vue';

@Component
export default class Step extends Vue {

	@Inject({ from : 'stepsComponentInject' })
	readonly stepsComponentInject?: Steps;

	@Prop({ default : false })
	readonly disabled: boolean;

	@Prop({ default : '' })
	readonly title: string;

	@Prop({ default : '' })
	readonly nextLabel: string;

	@Prop({ default : '' })
	readonly prevLabel: string;

	@Prop({ default : '' })
	readonly finishLabel: string;

	/**
	 * If true, this step should not show the "previous" button to go to the previous step.
	 */
	@Prop({ default : false })
	readonly hidePrev: boolean;

	@Prop({ default : false })
	readonly hideNext: boolean; // Don't allow forwards navigation from this step (e.g. final step with custom "finish" button in the step itself)

	/**
	 * If true, exclude this step from appearing in the progress dots in the navigation, and don't show the progress when on it.
	 */
	@Prop({ default : false })
	readonly noProgress: boolean;

	@Prop({ default : () => true })
	readonly onNext: (step: Step, oldStep: Step) => PossiblePromise<boolean>;

	@Prop({ default : () => true })
	readonly onPrevious: (step: Step, oldStep: Step) => PossiblePromise<boolean>;

	@Prop({ default : () => true })
	readonly beforeActivated: () => PossiblePromise<void>;

	get steps() {
		return this.stepsComponentInject ?? null;
	}

	get isActive() {
		return this.steps?.currentStep === this;
	}

	get isDisabled(): boolean {
		return this.disabled || this.stepGroup?.disabled;
	}

	/**
	 * @returns if this Step is in a StepGroup, the StepGroup component, otherwise null.
	 */
	get stepGroup() {
		return this.$closestAncestor((component: Vue) => component.$options.name === 'StepGroup') as StepGroup;
	}

	get stepIndex() {
		return this.steps?.steps.indexOf(this) ?? -1;
	}

	mounted() {
		this.steps?.registerStep(this);
	}

	beforeDestroy() {
		this.steps?.unregisterStep(this);
	}

	/**
	 * Make this step the currently active step.
	 */
	activate() {
		return this.steps?.activateStep(this);
	}

}
